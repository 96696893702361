body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.react-terminal-line {
  white-space: pre-wrap !important;
}

.react-terminal-wrapper {
  background: rgba(37, 42, 51, 0.9) !important;
  ;
}

.react-terminal-wrapper.react-terminal-light {
  background: rgba(221, 221, 221, 0.85) !important;
}

.h-100vh {
  min-height: 100vh;
}


.bg-holder {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 50%;
  overflow: hidden;
  will-change: transform, opacity, filter;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  z-index: 0
}


.card-img {
  border-radius: 16px;
  transform: translateZ(0);
  overflow: hidden;
  user-select: none;
  transition: all 200ms ease 0s;
  cursor: pointer;
  opacity: 0.7;
  box-shadow: rgba(55, 53, 47, 0.16) 0px 0px 0px 1px, rgba(167, 167, 167, 0.25) 0px 1px 2px;
}

.card-img-selected {
  opacity: 1;
  box-shadow: rgb(35, 131, 226) 0px 0px 0px 2px, rgba(182, 182, 182, 0.25) 0px 8px 12px;
}

.card-img:hover {
  opacity: 1;
}

.card-select-image:hover .card-img img {
  transform: scale(1.05);
  transition: transform 0.35s ease-in-out;
}


main {
  height: 100vh;
}

.table-row {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.table-row:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-row:active {
  background-color: rgba(0, 0, 0, 0.1);
}


/* floating-labels.css */

:root {
  --input-padding-x: .75rem;
  --input-padding-y: .75rem;
}

html,
body {
  height: 100%;
}

main {
  /* display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px; */
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: 0 auto;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.affiliate-terms-card {
  max-width: 100%;
  height: 100%;
  max-height: 400px;
  overflow-y: scroll;
}

.terms-content {
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
}

.tos-container {
  background-color: #f5f5f5;
  overflow-y: scroll;
}